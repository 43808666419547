import React, { useState } from 'react';
import styles from './styles.module.css'
/*
Page to gather clients info & email drawing + info to Empire
*/
const InfoPage = ({ passAddress, passImage}) => 
{
  
  //Used for button animation
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  //Holds Client Info
  const [formData, setFormData] = 
  useState({
    name: '',
    phoneNumber: '',
    email: '',
    address: passAddress
  });
  //Handles Form Changes
  const handleChange = (e) => 
  {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  //Handles Email Sending
  const handleSubmit = (e) => 
  {
    e.preventDefault();
    const emailBody = `<b>Client Information</b><br><b>Name:</b> ${formData.name}<br><b>Address: </b>${formData.address}<br><b>Number:</b> ${formData.phoneNumber}<br><b>Email:</b> ${formData.email}<br>`;
    setIsLoading(true);
    sendEmail(emailBody);
  };
  //Function to Email
  const sendEmail = (emailBody) => 
  {
    window.Email.send({
      SecureToken: '709b9f30-fc21-4fdf-a3ff-46a436ec60fe', // write your secure token
      To: 'argjend12345@gmail.com', // to include multiple emails you need to mention an array
      From: 'office@empirecreates.com',
      Subject: 'Fence Tool Submission',
      Body: emailBody, // Add the form data to the email body
      Attachments : [
        {
          name : "fence-tool-drawing.png", // You can change the name if you want
          data : passImage.split(",")[1] // Splitting to remove the data URL prefix
        }]
    })
      .then(message => {
        setIsLoading(false);
        if(message === "OK")
        {
          setIsSuccess(true);
          setTimeout(function()
          {
            document.getElementById("pageTitle").innerText = "Thank You for Submitting!"
            document.getElementById("subTitle").style.display = "inline";
            document.getElementById("infoForm").style.display = "none";
          }, 1000);
        }else{
            setIsSuccess(false);
            setIsLoading(false);
            setIsError(true);
            console.log("error-message: " + message);
            document.getElementById("pageTitle").innerText = "Error try again or change email"
            document.getElementById("subTitle").style.display = "none";
            document.getElementById("infoForm").style.display = "inline";
          setTimeout(function()
          {
            setIsError(false);
          }, 2500);
        }
      })
      .catch(error => {
        setIsSuccess(false);
        setIsLoading(false);
        setIsError(true);
        document.getElementById("pageTitle").innerText = "Error try again or change email"
        document.getElementById("subTitle").style.display = "none";
        document.getElementById("infoForm").style.display = "inline";
        console.error('Error Submitting:', error);
        setTimeout(function()
        {
          setIsError(false);
        }, 2500);
      });
  };

  return (
    <div style={{backgroundImage: "url('https://empirefences.com/wp-content/uploads/2024/12/LoginPage.jpg')", backgroundSize: 'cover',  backgroundPosition: 'center', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{textAlign: "center", height: '100vh' }}>
        <img style={{ padding: "0px 0px 20px 0px", maxWidth: "350px", width: "100%", marginTop: '40px'}} src="https://empirefences.com/wp-content/uploads/2024/07/empireCreatesLogoWhite2.png" alt="Company Logo" />
        <div style={{ textAlign: "center", color: "#fff" }}>
          <h2 id="pageTitle" style={{color: "white", marginTop: '10%'}}>Enter Info to Send Quote</h2>
        </div>
        <form id="infoForm" onSubmit={handleSubmit} style={{ textAlign: 'center'}}>
          <input type="text" name="name" style={{width: '250px', height: '35px', fontSize: '16px', textAlign: 'center'}} value={formData.name} onChange={handleChange} placeholder="Name" required />
          <br></br> <br></br>
          <input type="tel" name="phoneNumber" style={{width: '250px', height: '35px', fontSize: '16px', textAlign: 'center'}} value={formData.phoneNumber} onChange={handleChange} placeholder="Phone Number" required />
          <br></br> <br></br>
          <input type="email" name="email" style={{width: '250px', height: '35px', fontSize: '16px', textAlign: 'center'}} value={formData.email} onChange={handleChange} placeholder="Email" required />
          <br></br> <br></br>
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <button
                id="submitB"
                className={`${styles.button} ${isLoading ? styles.loader : ''} ${isSuccess ? styles.success : ''} ${isError ? styles.error : ''}`}
                >
                {isLoading ? '' : isSuccess ? 'Success' : isError ? 'Error' : 'Submit'}
            </button>
          </div>
        </form>
        <h2 id="subTitle" style={{color: "white", display: 'none', textAlign: 'center'}}>An Empire Representative will <br></br> get back to you shortly!</h2>
      </div>
    </div>
  
  );
}

export default InfoPage;
